*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* :root {
  --layout_backdrop:rgb(179, 179, 179);
  --body_background: white;
  --body_color: black;
  --link_color: navy;
  --primary_font_color:black;
  --header_background:rgb(218, 218, 218);
} */
:root {
  --layout_backdrop:#313a41;
  --body_background: #0a171f !important;
  --body_color: purple;
  --link_color: navy;
  --primary_font_color:white;
  --header_background:#202d35;
  --primary_btn:#0070d3;
  --primary_btn_font:#ffffff;
}
[data-theme="dark"] {
  --layout_backdrop:#313a41;
  --body_background: #0a171f !important;
  --body_color: purple;
  --link_color: navy;
  --primary_font_color:white;
  --header_background:#202d35;
}

/* * {
  transition: all 0.3s ease-in-out;
} */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 */

body {
  margin: 0;
  padding: 0;
  background: var(--body_background) !important;
  /*  background: url(https://static.vecteezy.com/system/resources/previews/019/765/478/non_2x/world-map-with-country-location-free-vector.jpg); */
}
