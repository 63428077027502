.submitBtn{
    border: none ;
    background: var(--body_background);
    color: white;
    padding: 5px 20px;
    border-radius: 10px;
    font-weight: 500;
}
.header,.footer{
    border: none !important;
}
.selectContainer{
    display: flex;
    width: 100%;
    max-width: 500px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.label{
    width: 100%;
    font-weight: 600;
    margin-bottom: 5px;
}
.select{
    padding: 5px 20px;
    width: 100%;
}