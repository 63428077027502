.container {
  margin: 30px;
}

.profilePicContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}
.profilePicContainer img {
  background-color: rgba(255, 255, 255, 0.39);
  padding: 30px;
  border: 2px solid rgba(201, 201, 201, 0.836);
  border-radius: 100%;
}

.profileChangeBtnContainer {
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.profileChangeBtnContainer button {
  padding: 5px 20px;
  color: var(--primary_btn_font);
  border: none;
  border-radius: 20px;

  background-color: var(--primary_btn);
}

.avatarContainer {
  margin-bottom: 15px;
  width: 100%;
  height: 100%;
}

.avatarContainer > div {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 5px;
}

.avatarContainer > div > img {
  width: 100%;
  height: 100%;
}

.active {
  background-color: rgba(75, 110, 228, 0.37) !important;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer button {
  text-align: center;
  border: none;
  padding: 5px 30px;
  border-radius: 20px;
  background: var(--primary_btn);
  width: 100%;
  color: var(--primary_btn_font);
}
