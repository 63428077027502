.container {
    display: flex;
    flex-direction: column;
    height: 100dvh !important; /* Fallback */
    position: relative;
    width: 100%;
    max-width: 700px;
    /*  background: var(--body_background) !important; */
    background-color: var(--body_background);
  }
  .header {
    position: sticky;
    top: 0;
    left: 0;
    padding: 10px 10px;
    /*  background: var(--header_background); */
    background-color: #0a171f;
    flex: 1 0 50px;
    display: flex;
    align-items: center;
    color: var(--primary_font_color);
    border-bottom: rgba(121, 121, 121, 0.575) solid 2px;
    margin: 0px 20px;
  }
  
  .body {
    flex: 1 1 100%;
    overflow-y: auto; /* Added to enable scrolling */
    width: 100%;
    padding: 10px;
  }
  
  .footerDesktop {
    background: green;
    flex: 1 0 100px;
  }
  
  /* .footerMobile {
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
  } */
  .footerMobile {
    padding: 10px;
  }
  .subContainerFooterMobile {
    /* background: rgb(174, 168, 168); */
    background-color: #202d35;
    height: 60px;
    border-radius: 30px;
    border: none;
    overflow: hidden;
    display: flex;
    padding: 6px 6px 6px 20px;
    align-items: center;
  }
  .footerMobile textarea {
    background: none;
    width: 100%;
    border: none;
    outline: none;
    color: white;
    resize: none;
    overflow-y: hidden;
  }
  .footerMobile input {
    background: none;
    width: 100%;
    border: none;
    outline: none;
    color: white;
  }
  .footerMobile button {
    /*  background: rgb(37, 32, 32); */
    background: var(--primary_btn);
    border: none;
    border-radius: 50%;
    padding: 12px;
  }
  
  .footerMobile img {
    width: 25px;
  }
  
  .leaveBtn {
    aspect-ratio: 1/1;
    object-fit: cover;
    width: 30px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .userImageContainer {
    width: 40px;
    margin-right: 5px;
  }
  .userImageContainer img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .userNameContainer h1 {
    font-size: 18px;
    margin: 0;
    padding: 0;
  }
  .userNameContainer span {
    font-size: 12px;
    color: greenyellow;
  }
  .message {
    display: flex;
    margin: 20px 10px;
  }
  .messageBody {
    background: #3e4649;
    width: fit-content;
    max-width: 500px;
    border: none;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    color: white;
    padding: 15px 20px;
  }
  .onRight {
    flex-direction: row-reverse;
  }
  .onRight .messageBody {
    background: #0071d9;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 0px;
  }
  
  /* Extra small devices (phones, less than 576px) */
  @media (max-width: 575.98px) {
    .messageBody {
      max-width: 300px;
    }
  }
  
  /* Small devices (tablets, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    .messageBody {
      max-width: 300px;
    }
  }
  
  /* Medium devices (desktops, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
    .custom-class {
      /* Styles for md screens */
      background-color: green;
    }
  }
  
  /* Large devices (large desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .custom-class {
      /* Styles for lg screens */
      background-color: yellow;
    }
  }
  
  /* Extra large devices (larger desktops, 1200px and up) */
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    .custom-class {
      /* Styles for xl screens */
      background-color: purple;
    }
  }
  
  /* Extra extra large devices (extra large desktops, 1400px and up) */
  @media (min-width: 1400px) {
    .custom-class {
      /* Styles for xxl screens */
      background-color: pink;
    }
  }
  