
 .userBox{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}

.userBox .dot{
    width: 10px;
    height: 10px;
    background-color: rgb(0, 196, 26);
    border-radius: 50%
}


