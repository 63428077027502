.container {
  background: yellow;
  width: 500px;
  height: 500px;
  position: relative;
}

.circle {
  background-color: red;
  width: 25px;
  aspect-ratio: 1/1;
  border-radius: 100%;
  position: absolute;
  animation: fadeIn 0.5s ease;
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}