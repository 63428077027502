.container {
  display: flex;
  width: 100%;
  max-width: 700px;
  flex-direction: column;
  height: 100dvh;
  position: relative;
  color: var(--primary_font_color);
  background: var(--body_background);
}
.body {
  flex: 1 1 100%;
  overflow-y: auto; /* Added to enable scrolling */

}
.header {
  background: var(--header_background);
  flex: 1 0 60px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  font-weight: 600;
}
.footerMobile {
  background: var(--header_background);
  flex: 1 0 60px;
  width: 100%;
  height: 60px;
/*   border-top-left-radius: 30px;
  border-top-right-radius: 30px; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}
.footerMobile img {
  width: 30px;
  aspect-ratio: 1/1;
}
