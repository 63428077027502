.container {
  height: 100%;
  padding: 10px;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;  /* For Internet Explorer and Edge */
}

.container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for WebKit browsers */
}

.fullGrid {
  display: grid;
  -moz-grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 20%;
  gap: 5px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.gridItem {
  justify-self: center;
  align-self: flex-start;
  font-size: 1.5rem;
  height: 100%;
  width: 100%;
  background-image: url(/public/images/icons/icons8-customer-50.png);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  animation: fadeIn 0.5s ease;
}
/* .avatar {
  background-image: url(/public/images/icons/icons8-customer-50.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 50px;
  cursor: pointer;
  animation: fadeIn 0.5s ease;
  width: 200px;
} */
.text {
  position: absolute;
  font-size: 1rem;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
