.container {
  margin: 20px;
  padding: 10px;
  background: rgba(255, 255, 255, 0.164);
  border-radius: 10px;
}
.containerTitle h2 {
  font-size: 18px;
  margin-bottom: 20px;
}
.btnContainer {
  margin-top: 10px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.btnContainer button {
  color: var(--primary_btn_font);
  background-color: var(--primary_btn);
  padding: 5px 30px;
  border: none;
  border-radius: 20px;
}

.inputContainer input {
  margin-top: 3px;
  width: 100%;
  border-radius: 10px;
  padding: 5px 10px;
  border: none;
  outline: none;
  background: rgba(255, 255, 255, 0.164);
}

.error {
  color: tomato;
}
